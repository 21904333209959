/**
 * コジェネ機器の画面か否かを返す
 */
function isCogenerations() {
    return this.$route.meta.deviceCategoryKey === 'cogenerations'
}

/**
 * ハイブリッド給湯機の画面か否かを返す
 */
function isHybridWaterHeaters(){
    return this.$route.meta.deviceCategoryKey === 'hybridWaterHeaters'
}

/**
 * ソーラーシステムの画面か否かを返す
 */
 function isSolarSystems(){
    return this.$route.meta.deviceCategoryKey === 'solarSystems'
}

/**
 * 太陽熱温水器の画面か否かを返す
 */
 function isSolarWaterHeaters(){
    return this.$route.meta.deviceCategoryKey === 'solarWaterHeaters'
}

/**
 * 画面が扱う機器の日本語名を返す
 */
function deviceCategoryName(){
    return {
        cogenerations:'コージェネレーション機器',
        hybridWaterHeaters:'ハイブリッド給湯機',
        electricHeatPumps:'電気ヒートポンプ給湯機',
        solarSystems:'ソーラーシステム',
        solarWaterHeaters:'太陽熱温水器',
    }[this.$route.meta.deviceCategoryKey]
}

/**
 * 画面が扱うpu_numberの日本語名を返す
 */
function devicePuNumberName(){
    return {
        cogenerations:'発電ユニット番号',
        hybridWaterHeaters:'ヒートポンプユニット番号',
        electricHeatPumps:'ヒートポンプユニット形式'
    }[this.$route.meta.deviceCategoryKey]
}

/**
 * 画面が扱う機器の英語名をlowerCamelCaseからUpperCamelCaseに変えて返す
 */
function upperDeviceCategoryKey(){
    const keyString = this.$route.meta.deviceCategoryKey
    return keyString.substring(0, 1).toUpperCase() + keyString.substring(1)
}

export default {
    computed:{
        isCogenerations,
        isHybridWaterHeaters,
        isSolarSystems,
        isSolarWaterHeaters,
        deviceCategoryName,
        devicePuNumberName,
        upperDeviceCategoryKey,
    }
}
<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">{{ deviceCategoryName }}登録</h2>
                <hr class="hp_flexspacer"/>
                <p>登録する機器にチェックを入れてください。</p>
            </div>
            <error-list />
            <table v-if="isSolarDevice" class="lists_table">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th class="lists_table_brand_name">ブランド事業者</th>
                        <th class="lists_table_brand_name">製造事業者名</th>
                        <th class="lists_table_brand_name">システム型式</th>
                        <th
                            class="lists_table_brand_name"
                            v-if="isSolarSystems"
                        >
                            集熱器型式
                        </th>
                        <th
                            class="lists_table_brand_name"
                            v-if="isSolarSystems"
                        >
                            {{tankName()}}
                        </th>
                        <th class="lists_table_certificate_number"><span>成績証明書</span><span>番号等</span></th>
                        <th class="lists_table_releaseYearMonth">情報公開日</th>
                        <th>項目検査結果</th>
                        <th class="uq_centerd">登録<br>チェック</th>
                    </tr>
                </thead>
                <tr v-for="(item, index) in csv" :key="'csvRow' + index">
                    <td>{{ item.no }}</td>
                    <td>{{ item.brand_name }}</td>
                    <td>{{ item.manufacturer_name }}</td>
                    <td>{{ item.system_type }}</td>
                    <td v-if="isSolarSystems">{{ item.heat_collector_type }}</td>
                    <td v-if="isSolarSystems">{{ item.heat_storage_tank_type }}</td>
                    <td>{{ item.certificate_number }}</td>
                    <td>{{ item.publish_date }}</td>
                    <td>{{ message(item) }}</td>
                    <td class="uq_centerd">
                        <input type="checkbox" :disabled="isNotUploadable(item)" :value="item.no" v-model="noList" />
                    </td>
                </tr>
            </table>
            <table v-else class="lists_table">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th class="lists_table_brand_name">ブランド事業者</th>
                        <th v-if="isHybrid">ヒートポンプユニット製造事業者名</th>
                        <th v-if="devicePuNumberName == 'ヒートポンプユニット番号'"
                            class="lists_table_pu_number"><span>ヒートポンプ</span><span>ユニット番号</span></th>
                        <th v-else
                            class="lists_table_pu_number">{{ devicePuNumberName }}</th>
                        <th class="lists_table_tu_number"><span>貯湯ユニット</span><span>品番</span></th>
                        <th class="lists_table_bb_number"><span>補助熱源機</span><span>品番</span></th>
                        <th class="lists_table_certificate_number"><span>成績証明書</span><span>番号等</span></th>
                        <th class="lists_table_releaseYearMonth">情報公開日</th>
                        <th>項目検査結果</th>
                        <th class="uq_centerd">登録<br>チェック</th>
                    </tr>
                </thead>
                <tr
                    v-for="(item, index) in csv"
                    :key="'csvRow' + index"
                >
                    <td>{{ item.no }}</td>
                    <td>{{ item.brand_name }}</td>
                    <td v-if="isHybrid">{{ item.pu_manufacturer_name }}</td>
                    <td>{{ item.pu_number }}</td>
                    <td>{{ item.tu_number }}</td>
                    <td>{{ item.bb_number }}</td>
                    <td>{{ item.certificate_number }}</td>
                    <td>{{ item.publish_date }}</td>
                    <td>{{ message(item) }}</td>
                    <td class="uq_centerd">
                        <input
                            type="checkbox"
                            :disabled="isNotUploadable(item)"
                            :value="item.no"
                            v-model="noList"
                        />
                    </td>
                </tr>
            </table>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="back"
                    class="cancel"
                    ref="jest-device-confirm-back"
                />
                <app-button
                    name="登録"
                    @click="deviceUpload"
                    ref="jest-device-confirm-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * 機器登録
 *
 * [算出プロパティ]
 *
 * [イベント処理]
 */
import ErrorList from '@/components/molecules/ErrorList'
import device from '@/mixins/device'
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties, move } from '@/util'

export default {
    name:'OrganizationDeviceUploadConfirm',
    components:{
        ErrorList,
    },
    mixins:[device],
    computed:{
        ...mapGetters({
            csv:'file/getCsv',
            warning:'file/getWarning',
            data:'file/noList',
        }),
        ...mapComputedProperties([
            'noList'
        ]),
        message(){
            return function (item) {
                if (Object.keys(this.warning.errors).includes(item.no)) {
                    return this.errorMessage(this.warning.errors[item.no])
                } else if (this.warning.duplicates.includes(item.no)) {
                    return 'CSV内に重複するデータが存在します。'
                } else if (this.warning.exists.includes(item.no)) {
                    return '登録済のデータです。'
                } else {
                    return 'アップロード可'
                }
            }
        },
        isNotUploadable(){
            return function (item) {
                if (Object.keys(this.warning.errors).includes(item.no)) {
                    return true
                } else if (this.warning.duplicates.includes(item.no)) {
                    return true
                } else if (this.warning.exists.includes(item.no)) {
                    return true
                } else {
                    return false
                }
            }
        },
        errorMessage(){
            return function(errors){
                return Object.keys(errors).reduce((text, item) => text + errors[item], '')
            }
        },
        isHybrid() {
            return this.$route.meta.deviceCategoryKey === 'hybridWaterHeaters'
        }
    },
    mounted(){
        this.update({
            noList:[]
        })
    },
    methods:{
        ...mapActions({
            update:'file/update',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        isSolarDevice() {
            const solarDevices = ['solarSystems', 'solarWaterHeaters']
            return solarDevices.includes(this.$route.meta.deviceCategoryKey)
        },
        isSolarSystems() {
            return this.$route.meta.deviceCategoryKey === 'solarSystems'
        },
        isSolarWaterHeaters() {
            return this.$route.meta.deviceCategoryKey === 'solarWaterHeaters'
        },
        tankName(){
            const tankName = {
                cogenerations: null,
                hybridWaterHeaters: null,
                electricHeatPumps: null,
                solarSystems: '蓄熱槽型式',
                solarWaterHeaters: null,
            }[this.$route.meta.deviceCategoryKey]
            return tankName
        },
        async deviceUpload(){
            const path = {
                cogenerations:'/organization/cogeneration_systems',
                hybridWaterHeaters:'/organization/hybrid_water_heaters',
                electricHeatPumps: '/organization/electric_heat_pumps',
                solarSystems: '/organization/solar_systems',
                solarWaterHeaters: '/organization/solar_water_heaters',
            }[this.$route.meta.deviceCategoryKey]
            this.clearError()
            this.$store.dispatch('file/upload' + this.upperDeviceCategoryKey)
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        move(this.$router, path)
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器アップロード画面に戻る
         */
        back(){
            const path = {
                cogenerations:'/organization/cogeneration_systems/upload',
                hybridWaterHeaters:'/organization/hybrid_water_heaters/upload',
                electricHeatPumps: '/organization/electric_heat_pumps/upload',
                solarSystems: '/organization/solar_systems/upload',
                solarWaterHeaters: '/organization/solar_water_heaters/upload',
            }[this.$route.meta.deviceCategoryKey]
            move(this.$router, path)
        }
    }
}
</script>

<style scoped>
.lists_table thead th{
    padding-right: 16px;
}
.uq_centerd{
    text-align: center;
}
th.uq_centerd{
    line-height: 12px;
    min-width: 5em;
}
</style>